import React from "react";
import { IconBackspace, IconCrane } from "@tabler/icons-react";
import { UnauthorizedRedirectHost } from "../../helpers/Constants";

export function SideBar() {
  return (
    <aside className="navbar navbar-vertical navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebar-menu"
          aria-controls="sidebar-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <h1 className="navbar-brand navbar-brand-autodark">
          <a href=".">
            <img
              src="./img/shipman-logo.jpg"
              alt="Shipman Logo"
              className="navbar-brand-image"
              width="110"
              height="32"
            />
          </a>
        </h1>

        <div className="collapse navbar-collapse" id="sidebar-menu">
          <ul className="navbar-nav pt-lg-3">
            <li className="nav-item">
              <a className="nav-link" href={UnauthorizedRedirectHost}>
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconBackspace />
                </span>
                <span className="nav-link-title">Back to ShipmanPM</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./leads">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <IconCrane />
                </span>
                <span className="nav-link-title">MBS</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
}
