export const UnauthorizedRedirectHost = "https://www.shipmanpm.com";
export const JWT_PARAM_NAME: string = "jwt";

export const LeadStatusFromEnum = (input: number) => {
  switch (input) {
    case 0:
      return "Lead";
    case 1:
      return "PreparingQuote";
    case 2:
      return "QuoteRequested";
    case 3:
      return "ContractSent";
    case 4:
      return "ContractSigned";
    case 5:
      return "UnderConstruction";
    case 6:
      return "Completed";
  }
};
