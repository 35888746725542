import React, { useEffect, useState } from "react";
import { fetchAuthorized } from "../helpers/FetchAuthorized";
import { useNavigate, useParams } from "react-router-dom";
import { LeadViewModel } from "../models/LeadViewModel";
import {
  IconCurrencyDollar,
  IconMail,
  IconMessageCircle,
  IconPhone,
} from "@tabler/icons-react";
import { LeadStatusFromEnum } from "../helpers/Constants";
import { EventViewModel } from "../models/EventViewModel";

enum FeedMode {
  All,
  Photo,
  Attachments,
}

export function LeadView() {
  const [lead, setLead] = useState<LeadViewModel | null>(null);
  const [loading, setLoading] = useState(true);
  const [feedMode, setFeedMode] = useState(FeedMode.All);
  const [newComment, setNewComment] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const isNew = params.leadId === "0";

  const populateLeadData = async () => {
    if (!isNew) {
      const response = await fetchAuthorized(`Lead/${params.leadId}`);
      if (!response.ok) {
        alert("Invalid leadId");
        return;
      }
      const data = await response.json();
      setLead(data);
    } else {
      setLead({
        actualCost: 0,
        amountQuoted: 0,
        beamDepth: "",
        budget: 0,
        city: "",
        concreteDepth: "",
        contractPrice: 0,
        created: new Date(),
        customerName: "",
        dateQuoted: undefined,
        email: "",
        endDate: undefined,
        estimatedCost: 0,
        id: 0,
        leadStatus: 0,
        numberOfBeams: "",
        phone: "",
        profit: 0,
        rollUpDoors: "",
        roofPitch: "",
        sideWallHeight: "",
        size: "",
        specialProvisions: "",
        startDate: undefined,
        state: "",
        steelGauge: "",
        street: "",
        walkDoors: "",
        windows: "",
        zip: "",
      });
    }
    setLoading(false);
  };

  const prepareQuote = async () => {
    lead!.leadStatus = 1;
    await saveLeadData();
  };

  const closeLead = async () => {
    lead!.leadStatus = 6;
    await saveLeadData();
  };

  const requestQuote = async () => {
    lead!.leadStatus = 2;
    await saveLeadData();
    alert(
      "An email has been sent requesting a contract be sent to customer with quote."
    );
  };

  const redirectContractSend = async () => {
    const response = await fetchAuthorized(
      `Lead/${params.leadId}/contractSendUrl`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      alert("Error loading Docusign");
      return;
    }

    window.location.href = await response.text();
  };
  const beginConstruction = async () => {
    lead!.leadStatus = 5;
    await saveLeadData();
  };
  const completeConstruction = async () => {
    lead!.leadStatus = 6;
    await saveLeadData();
  };

  const saveComment = async () => {
    const response = await fetchAuthorized(`Lead/${params.leadId}/addComment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Id: params.leadId, Comment: newComment }),
    });

    setNewComment("");
    if (!response.ok) {
      alert("Error saving comment");
      return;
    }

    window.location.reload();
  };
  const saveLeadData = async () => {
    console.log(lead);
    const response = await fetchAuthorized(
      isNew ? "Lead" : `Lead/${params.leadId}`,
      {
        method: isNew ? "POST" : "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(lead),
      }
    );

    if (!response.ok) {
      alert("Error saving lead");
      return;
    }

    if (isNew) {
      const id = await response.json();
      navigate(`/leads/${id}`);
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    populateLeadData().catch(console.error);
  }, []);

  const handleInputChange = (
    key: string,
    value: any,
    type: string = "string"
  ) => {
    if (lead) {
      if (type === "number") {
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
          // @ts-ignore
          lead[key] = value;
        }
      } else {
        // @ts-ignore
        lead[key] = value;
      }

      setLead(lead);
    }
  };

  const RenderLead = (lead: LeadViewModel | null) => {
    return (
      <>
        <div className="mb-3">
          <label className="form-label">Lead Details</label>
          <input
            type="text"
            className="form-control"
            placeholder="Customer Name"
            defaultValue={lead?.customerName || ""}
            onChange={(event) => {
              handleInputChange("customerName", event.target.value);
            }}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="mb-3">
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconMail />
              </span>
              <input
                type="text"
                className="form-control"
                defaultValue={lead?.email || ""}
                onChange={(event) => {
                  handleInputChange("email", event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="mb-3">
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconPhone />
              </span>
              <input
                type="text"
                className="form-control"
                defaultValue={lead?.phone || ""}
                onChange={(event) => {
                  handleInputChange("phone", event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="mb-3">
            <label className="form-label">Street</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.street || ""}
              onChange={(event) => {
                handleInputChange("street", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6"></div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">City</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.city || ""}
              onChange={(event) => {
                handleInputChange("city", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">State</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.state || ""}
              onChange={(event) => {
                handleInputChange("state", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Zip</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.zip || ""}
              onChange={(event) => {
                handleInputChange("zip", event.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Budget</label>
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCurrencyDollar />
              </span>
              <input
                type="text"
                className="form-control"
                defaultValue={lead?.budget || ""}
                onChange={(event) => {
                  handleInputChange("budget", event.target.value, "number");
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderBuildingSpecs = (lead: LeadViewModel | null) => {
    return (
      <>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Size</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.size || ""}
              onChange={(event) => {
                handleInputChange("size", event.target.value, "number");
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Gauge of Steel</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.steelGauge || ""}
              onChange={(event) => {
                handleInputChange("steelGauge", event.target.value, "number");
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Concrete Depth</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.concreteDepth || ""}
              onChange={(event) => {
                handleInputChange(
                  "concreteDepth",
                  event.target.value,
                  "number"
                );
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Beam Depth</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.beamDepth || ""}
              onChange={(event) => {
                handleInputChange("beamDepth", event.target.value, "number");
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label"># Beams</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.numberOfBeams || ""}
              onChange={(event) => {
                handleInputChange(
                  "numberOfBeams",
                  event.target.value,
                  "number"
                );
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Wall Height</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.sideWallHeight || ""}
              onChange={(event) => {
                handleInputChange(
                  "sideWallHeight",
                  event.target.value,
                  "number"
                );
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Roof Pitch</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.roofPitch || ""}
              onChange={(event) => {
                handleInputChange("roofPitch", event.target.value, "number");
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Roll up Doors</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.rollUpDoors || ""}
              onChange={(event) => {
                handleInputChange("rollUpDoors", event.target.value, "number");
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Walk Doors</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.walkDoors || ""}
              onChange={(event) => {
                handleInputChange("walkDoors", event.target.value, "number");
              }}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Windows</label>
            <input
              type="text"
              className="form-control"
              defaultValue={lead?.windows || ""}
              onChange={(event) => {
                handleInputChange("windows", event.target.value, "number");
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const RenderCosts = (lead: LeadViewModel | null) => {
    return (
      <>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Contract Price</label>
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCurrencyDollar />
              </span>
              <input
                type="text"
                className="form-control"
                defaultValue={lead?.contractPrice || ""}
                onChange={(event) => {
                  handleInputChange(
                    "contractPrice",
                    event.target.value,
                    "number"
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Estimated Cost</label>
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCurrencyDollar />
              </span>
              <input
                type="text"
                className="form-control"
                defaultValue={lead?.estimatedCost || ""}
                onChange={(event) => {
                  handleInputChange(
                    "estimatedCost",
                    event.target.value,
                    "number"
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Actual Cost</label>
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCurrencyDollar />
              </span>
              <input
                type="text"
                className="form-control"
                defaultValue={lead?.actualCost || ""}
                onChange={(event) => {
                  handleInputChange("actualCost", event.target.value, "number");
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Profit</label>
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCurrencyDollar />
              </span>
              <input
                type="text"
                disabled={true}
                className="form-control"
                defaultValue={lead?.profit || ""}
                onChange={(event) => {
                  handleInputChange("profit", event.target.value, "number");
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderFeed = (events: EventViewModel[] | undefined) => {
    if (!events) return <></>;

    if (feedMode == FeedMode.All)
      return (
        <>
          {events.map((e) => (
            <div className="list-group-item" key={e.id}>
              <div className="row align-items-center">
                <div className="col text-truncate">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: e.text.replace("img-responsive", ""),
                    }}
                  ></div>
                  <div className="d-block text-muted text-truncate mt-n1">
                    {e.created + " -- " + e.createdBy}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      );

    if (feedMode == FeedMode.Photo)
      return (
        <>
          {events
            .filter((e) => !!e.media)
            .map((e) => (
              <div className="list-group-item" key={e.id}>
                <div className="row align-items-center">
                  <div className="col text-truncate">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: e.text.replace("img-responsive", ""),
                      }}
                    ></div>
                    <div className="d-block text-muted text-truncate mt-n1">
                      {e.created + " -- " + e.createdBy}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </>
      );

    return <></>;
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-header d-print-none">
          <div className="container-xl">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title">
                  {lead?.customerName} (Status:{" "}
                  {LeadStatusFromEnum(lead?.leadStatus!)})
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="container-xl">
            <div className="row row-cards">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    {loading ? (
                      `Loading...`
                    ) : (
                      <>
                        <div className="row row-cards">{RenderLead(lead)}</div>
                        {lead?.leadStatus && lead?.leadStatus > 0 ? (
                          <>
                            <hr />
                            <div className="row row-cards">
                              {RenderCosts(lead)}
                            </div>
                            <hr />
                            <div className="row row-cards">
                              {RenderBuildingSpecs(lead)}
                            </div>
                            <hr />
                          </>
                        ) : null}
                        <div className="row row-cards">
                          {lead?.leadStatus && lead?.leadStatus > 0 ? (
                            <>
                              <div className="col-sm-6 col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Start Date
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      lead?.startDate
                                        ? lead?.startDate.toLocaleDateString(
                                            "en-US"
                                          ) || ""
                                        : ""
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">End Date</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      lead?.endDate
                                        ? lead?.endDate.toLocaleDateString(
                                            "en-US"
                                          ) || ""
                                        : ""
                                    }
                                  />
                                </div>
                              </div>{" "}
                            </>
                          ) : null}
                          <div className="mb-3">
                            <label className="form-label">
                              Special Provisions
                            </label>
                            <textarea
                              rows={8}
                              className="form-control"
                              defaultValue={lead?.specialProvisions || ""}
                              onChange={(event) => {
                                handleInputChange(
                                  "specialProvisions",
                                  event.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="card-footer">
                    <div className="d-flex">
                      {lead?.leadStatus !== 6 ? (
                        <a className="btn btn-warning" onClick={closeLead}>
                          Close Lead
                        </a>
                      ) : null}
                      {lead?.leadStatus === 0 ? (
                        <a className="btn btn-success" onClick={prepareQuote}>
                          Prepare Quote
                        </a>
                      ) : null}
                      {lead?.leadStatus === 1 ? (
                        <a className="btn btn-success" onClick={requestQuote}>
                          Request Quote
                        </a>
                      ) : null}
                      {lead?.leadStatus === 2 ? (
                        <a
                          className="btn btn-success"
                          onClick={redirectContractSend}
                        >
                          Send Contract
                        </a>
                      ) : null}
                      {lead?.leadStatus === 3 ? (
                        <a className="btn btn-success disabled">
                          Contract Sent, Awaiting Signature
                        </a>
                      ) : null}
                      {lead?.leadStatus === 4 ? (
                        <a
                          className="btn btn-success"
                          onClick={beginConstruction}
                        >
                          Begin Construction
                        </a>
                      ) : null}
                      {lead?.leadStatus === 5 ? (
                        <a
                          className="btn btn-success"
                          onClick={completeConstruction}
                        >
                          Complete
                        </a>
                      ) : null}
                      <a
                        className="btn btn-primary ms-auto"
                        onClick={saveLeadData}
                      >
                        Save
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <ul className="nav nav-pills card-header-pills">
                      <li
                        className="nav-item"
                        onClick={() => {
                          setFeedMode(FeedMode.All);
                        }}
                      >
                        <a
                          className={`nav-link ${
                            feedMode == FeedMode.All ? "active" : ""
                          }`}
                        >
                          Feed
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          setFeedMode(FeedMode.Photo);
                        }}
                      >
                        <a
                          className={`nav-link ${
                            feedMode == FeedMode.Photo ? "active" : ""
                          }`}
                        >
                          Photo Gallery
                        </a>
                      </li>
                      <li className="nav-item ms-auto">
                        <a
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-comment"
                        >
                          <IconMessageCircle />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="list-group list-group-flush list-group-hoverable">
                    {loading ? `Loading...` : RenderFeed(lead?.events)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal modal-blur fade"
        id="modal-comment"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Enter Comments</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Comment Text</label>
                <textarea
                  className="form-control"
                  placeholder="Customer Name"
                  defaultValue={newComment || ""}
                  onChange={(event) => {
                    setNewComment(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn me-auto"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={saveComment}
              >
                Save Comment
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
