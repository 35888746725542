import React from "react";
import { getCurrentUser } from "../../helpers/UserService";

export function NavBar() {
  return (
    <header className="navbar navbar-expand-md navbar-light d-none d-lg-flex d-print-none">
      <div className="container-xl">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-menu"
          aria-controls="navbar-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-nav flex-row order-md-last">
          <div className="d-none d-xl-block ps-2">
            <div>{getCurrentUser()?.sub}</div>
            <div className="mt-1 small text-muted">
              {getCurrentUser()?.roles.join("|")}
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="navbar-menu">
          <div>
            {/*<form action="./" method="get" autoComplete="off">*/}
            {/*  <div className="input-icon">*/}
            {/*    <span className="input-icon-addon">*/}
            {/*      <IconSearch />*/}
            {/*    </span>*/}
            {/*    <input*/}
            {/*      type="text"*/}
            {/*      defaultValue=""*/}
            {/*      className="form-control"*/}
            {/*      placeholder="Search…"*/}
            {/*      aria-label="Search in website"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</form>*/}
          </div>
        </div>
      </div>
    </header>
  );
}
