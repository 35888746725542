import React, { useEffect, useState } from "react";
import { fetchAuthorized } from "../helpers/FetchAuthorized";
import { useNavigate, useParams } from "react-router-dom";
import { LeadViewModel } from "../models/LeadViewModel";

export function LeadListingView() {
  const [leads, setLeads] = useState<LeadViewModel[]>([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  const populateLeadData = async () => {
    const response = await fetchAuthorized(`Lead`);
    if (!response.ok) {
      alert("Error loading leads");
      return;
    }
    const data = await response.json();
    setLeads(
      data.map((d: LeadViewModel) => {
        return {
          ...d,
          startDate: new Date(d.startDate!),
          endDate: new Date(d.endDate!),
          created: new Date(d.created),
          dateQuoted: new Date(d.dateQuoted!),
        };
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    populateLeadData().catch(console.error);
  }, []);

  function handleClick(id: number) {
    navigate(`/leads/${id}`);
  }

  return (
    <div className="page-wrapper">
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">Leads/Quotes/Contracts</div>
              <h2 className="page-title">Metal Building Specialists</h2>
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                <span className="d-none d-sm-inline">
                  <a onClick={() => handleClick(0)} className="btn btn-primary">
                    New Lead
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="card">
              <div className="card-header">Contracts</div>
              <div className="card-body">
                {loading || !leads ? (
                  "Loading..."
                ) : (
                  <div className="table-responsive">
                    <table className="table table-vcenter">
                      <thead>
                        <tr>
                          <th>Address</th>
                          <th>Customer Name</th>
                          <th>Contract Price</th>
                          <th>Est. Cost</th>
                          <th>Est. Profit</th>
                          <th>Actual Cost</th>
                          <th>Actual Profit</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leads
                          .filter(
                            (l) => l.leadStatus !== 0 && l.leadStatus !== 1
                          )
                          .map((lead) => {
                            return (
                              <tr
                                key={lead.id}
                                onClick={() => handleClick(lead.id)}
                              >
                                <td>{lead.street}</td>
                                <td>{lead.customerName}</td>
                                <td>{lead.contractPrice}</td>
                                <td>{lead.estimatedCost}</td>
                                <td>
                                  {(lead.contractPrice ?? 0) -
                                    (lead.estimatedCost ?? 0)}
                                </td>
                                <td>{lead.actualCost}</td>
                                <td>{lead.profit}</td>
                                <td>
                                  {lead.startDate!.toLocaleDateString("en-US")}
                                </td>
                                <td>
                                  {lead.endDate!.toLocaleDateString("en-US")}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-header">Quotes</div>
              <div className="card-body">
                {loading || !leads ? (
                  "Loading..."
                ) : (
                  <div className="table-responsive">
                    <table className="table table-vcenter">
                      <thead>
                        <tr>
                          <th>Address</th>
                          <th>Customer Name</th>
                          <th>Date Quoted</th>
                          <th>Amount Quoted</th>
                          <th>Est. Cost</th>
                          <th>Start Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leads
                          .filter((l) => l.leadStatus === 1)
                          .map((lead) => {
                            return (
                              <tr
                                key={lead.id}
                                onClick={() => handleClick(lead.id)}
                              >
                                <td>{lead.street}</td>
                                <td>{lead.customerName}</td>
                                <td>
                                  {lead.dateQuoted!.toLocaleDateString("en-US")}
                                </td>
                                <td>{lead.amountQuoted}</td>
                                <td>{lead.estimatedCost}</td>
                                <td>
                                  {lead.startDate!.toLocaleDateString("en-US")}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-header">Leads</div>
              <div className="card-body">
                {loading || !leads ? (
                  "Loading..."
                ) : (
                  <div className="table-responsive">
                    <table className="table table-vcenter">
                      <thead>
                        <tr>
                          <th>Address</th>
                          <th>Customer Name</th>
                          <th>Budget</th>
                          <th>Phone Number</th>
                          <th>Email</th>
                          <th>Date Created</th>
                          <th>Most Recent Contact</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leads
                          .filter((l) => l.leadStatus === 0)
                          .map((lead) => {
                            return (
                              <tr
                                key={lead.id}
                                onClick={() => handleClick(lead.id)}
                              >
                                <td>{lead.street}</td>
                                <td>{lead.customerName}</td>
                                <td>{lead.budget}</td>
                                <td>{lead.phone}</td>
                                <td className="text-muted">
                                  <a href="#" className="text-reset">
                                    {lead.email}
                                  </a>
                                </td>
                                <td>
                                  {lead.created.toLocaleDateString("en-US")}
                                </td>
                                <td>
                                  {lead.created.toLocaleDateString("en-US")}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
