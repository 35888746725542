import jwtDecode from "jwt-decode";

export interface UserJwt {
  iss: string;
  sub: string;
  iat: number;
  exp: number;
  roles: string[];
}

export function getCurrentUser(): UserJwt | null {
  if (!localStorage.token) return null;

  let decodedUser = jwtDecode(localStorage.token) as UserJwt;
  if (!decodedUser) return null;

  return decodedUser;
}
