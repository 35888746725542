import { UnauthorizedRedirectHost } from "./Constants";

function updateOptions(options?: RequestInit) {
  const update = { ...options };
  if (localStorage.token) {
    update.headers = {
      ...update.headers,
      Authorization: `Bearer ${localStorage.token}`,
    };
  } else {
    window.location.href = UnauthorizedRedirectHost;
  }
  return update;
}

export async function fetchAuthorized(
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response> {
  return fetch(input, updateOptions(init)).then((resp) => {
    if (resp.status === 401) {
      localStorage.removeItem("token");
      window.location.href = UnauthorizedRedirectHost;
    }

    return resp;
  });
}
