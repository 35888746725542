import React, { useEffect, useState } from "react";
import {
  IconBath,
  IconBed,
  IconCalendar,
  IconCalendarOff,
  IconCurrencyDollar,
  IconMail,
  IconPhone,
  IconRuler,
} from "@tabler/icons-react";
import { TenantDetails } from "../models/TenantDetails";
import { fetchAuthorized } from "../helpers/FetchAuthorized";
import { useParams } from "react-router-dom";
import { LeaseViewModel } from "../models/LeaseViewModel";

export function LeaseView() {
  const [lease, setLease] = useState<LeaseViewModel | null>(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const populateLeaseData = async () => {
    const response = await fetchAuthorized(`Lease?id=${params.leaseId}`);
    if (!response.ok) {
      alert("Invalid LeaseId");
      return;
    }
    const data = await response.json();
    setLease({
      ...data,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
    });
    setLoading(false);
  };

  useEffect(() => {
    populateLeaseData().catch(console.error);
  }, []);

  const RenderTenant = (tenant: TenantDetails, label?: string) => {
    return (
      <div className="col-md">
        {label ? <label className="form-label">{label}</label> : null}
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            value={tenant.name}
            disabled={true}
          />
        </div>
        <div className="mb-3">
          <div className="input-icon">
            <span className="input-icon-addon">
              <IconMail />
            </span>
            <input
              type="text"
              className="form-control"
              value={tenant.email}
              disabled={true}
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="input-icon">
            <span className="input-icon-addon">
              <IconPhone />
            </span>
            <input
              type="text"
              className="form-control"
              value={tenant.phone}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  };

  const RenderLease = (lease: any) => {
    return (
      <>
        <div className="col-sm-4 col-md-2">
          <div className="mb-3">
            <label className="form-label">Rent</label>{" "}
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCurrencyDollar />
              </span>
              <input
                type="text"
                className="form-control"
                value={lease.rent}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-2">
          <div className="mb-3">
            <label className="form-label">Deposit</label>{" "}
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCurrencyDollar />
              </span>
              <input
                type="text"
                className="form-control"
                value={lease.deposit}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-2">
          <div className="mb-3">
            <label className="form-label">Daily Fee</label>{" "}
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCurrencyDollar />
              </span>
              <input
                type="text"
                className="form-control"
                value={lease.dailyFee}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-2">
          <div className="mb-3">
            <label className="form-label">Late Fee</label>{" "}
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCurrencyDollar />
              </span>
              <input
                type="text"
                className="form-control"
                value={lease.lateFee}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-2">
          <div className="mb-3">
            <label className="form-label">Days Until Late</label>
            <input
              type="test"
              className="form-control"
              value={lease.daysUntilLate}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Lease Start Date</label>
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCalendar />
              </span>
              <input
                type="test"
                className="form-control"
                value={lease.startDate.toLocaleDateString("en-US")}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Lease End Date</label>
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCalendar />
              </span>
              <input
                type="test"
                className="form-control"
                value={lease.endDate.toLocaleDateString("en-US")}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <div className="mb-3">
            <label className="form-label">Move Out Date</label>
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconCalendarOff />
              </span>
              <input
                type="test"
                className="form-control"
                value={
                  lease.moveOutDate
                    ? lease.moveOutDate.toLocaleDateString("en-US")
                    : ""
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-2">
          <div className="mb-3">
            <label className="form-label">Sqft</label>{" "}
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconRuler />
              </span>
              <input
                type="text"
                className="form-control"
                value={lease.squareFeet}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-2">
          <div className="mb-3">
            <label className="form-label">Beds</label>{" "}
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconBed />
              </span>
              <input
                type="text"
                className="form-control"
                value={lease.beds}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-2">
          <div className="mb-3">
            <label className="form-label">Baths</label>{" "}
            <div className="input-icon">
              <span className="input-icon-addon">
                <IconBath />
              </span>
              <input
                type="email"
                className="form-control"
                value={lease.baths}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="page-wrapper">
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">
                {lease ? lease.propertyName : null}
              </div>
              <h2 className="page-title">{lease ? lease.unitName : null}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="card">
              <div className="card-header">
                <ul
                  className="nav nav-tabs card-header-tabs nav-fill"
                  data-bs-toggle="tabs"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      href="#tabs-details"
                      className="nav-link active"
                      data-bs-toggle="tab"
                      aria-selected="true"
                      role="tab"
                    >
                      Details
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      href="#tabs-feed"
                      className="nav-link"
                      data-bs-toggle="tab"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                    >
                      Feed
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      href="#tabs-attachments"
                      className="nav-link"
                      data-bs-toggle="tab"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                    >
                      Attachments
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      href="#tabs-statement"
                      className="nav-link"
                      data-bs-toggle="tab"
                      aria-selected="false"
                      tabIndex={-1}
                      role="tab"
                    >
                      Statement
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-pane" id="tabs-details" role="tabpanel">
                {loading || !lease ? (
                  "Loading..."
                ) : (
                  <div className="card-body">
                    <div className="row row-cards pb-3">
                      {RenderTenant(lease.tenant)}
                    </div>
                    <div className="row row-cards">{RenderLease(lease)}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
