import React, { useEffect } from "react";
import { NavBar } from "./components/layout/NavBar";
import { SideBar } from "./components/layout/SideBar";
import { Route, Routes, useSearchParams } from "react-router-dom";
import AppRoutes from "./App-Routes";
import { JWT_PARAM_NAME, UnauthorizedRedirectHost } from "./helpers/Constants";

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  if (!localStorage.token) {
    const urlToken = searchParams.get(JWT_PARAM_NAME);
    if (!urlToken) {
      localStorage.removeItem("token");
      window.location.href =
        UnauthorizedRedirectHost +
        "/manage/jwtLogin.aspx?redirectPath=%2Fleads";
    } else {
      localStorage.setItem("token", urlToken);
    }
  }

  useEffect(() => {
    searchParams.delete(JWT_PARAM_NAME);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <div className="page">
      <SideBar />
      <NavBar />
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
    </div>
  );
}

export default App;
