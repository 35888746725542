import { LeaseView } from "./components/LeaseView";
import { Home } from "./components/Home";
import { LeadListingView } from "./components/LeadListingView";
import { LeadView } from "./components/LeadView";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/lease/:leaseId",
    element: <LeaseView />,
  },
  {
    path: "/leads",
    element: <LeadListingView />,
  },
  {
    path: "/leads/:leadId",
    element: <LeadView />,
  },
];

export default AppRoutes;
